.temporal {
  font-size: 1.6rem;
  padding: 8rem;
  color: $Black;
}
@media screen and (min-width: 1000px) {
  .temporal {
    font-size: 1.8rem;
    padding: 2rem 5rem;
  }
}