.cart {
  text-align: center;
  padding: 4rem 2rem;
  &__grid {
    display: grid;
    gap: 6rem;
    &__items {
      display: grid;
      gap: 3rem;
      &__empty {
        &-title {
          text-align: center;
          font-size: 2.2rem;
          font-weight: 600;
          color: $Black;
          margin-top: 4rem;
        }
        &-img {
          width: 100%;
          max-width: 45rem;
          margin: 0 auto;
          animation-name: emptyCart;
          animation-duration: 2s;
          animation-iteration-count: infinite;
          animation-direction: alternate;
        }
        &-link {
          width: max-content;
          font-size: 1.6rem;
          color: $Black;
          position: relative;
          margin: 2rem auto 0;
          &::before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: .2rem;
            background-color: $Orange;
            transition: clip-path .3s;
            clip-path: circle(0% at 50% 50%);
          }
          &:hover::before {
            clip-path: circle(70.7% at 50% 50%);
          }
        }
      }
    }
    &__info {
      width: 100%;
      max-width: 50rem;
      margin: 0 auto;
      background-color: $White;
      padding: 2rem;
      border-radius: 1.5rem;
      box-shadow: 5px 5px 10px 0px rgba($Black, 0.15);
      &__title {
        font-weight: 800;
        font-size: 2rem;
        padding: 1rem 0;
        color: $Black;
        border-bottom: .1rem solid $Gray;
      }
      &__container {
        display: grid;
        grid-auto-flow: row;
        gap: 1rem;
        margin: 2rem 0;
        &__div {
          display: grid;
          grid-auto-flow: column;
          justify-content: space-between;
          align-items: center;
          &-name {
            font-size: 1.6rem;
            color: $Black;
          }
          &-price {
            font-weight: 600;
            font-size: 1.6rem;
            color: $Black;
          }
        }
      }
      &__div {
        display: grid;
        grid-auto-flow: column;
        justify-content: space-between;
        align-items: center;
        border-top: .1rem solid $Gray;
        padding: 2rem 0 3rem;
        &-name {
          font-size: 1.6rem;
          color: $Black;
        }
        &-price {
          color: $Black;
          font-size: 1.6rem;
          font-weight: 600;
        }
      }
      &__button {
        background-color: $Orange;
        width: 100%;
        padding: 1.1rem;
        font-size: 1.8rem;
        color: $White;
        font-weight: 600;
        text-transform: uppercase;
        border-radius: .5rem;
        border: .2rem solid $Orange;
        transition-property: background-color color transform;
        transition-duration: .3s;
        cursor: pointer;
        &:hover {
          background-color: $White;
          color: $Orange;
        }
        &:active {
          transform: translateY(.2rem);
        }
      }
    }
  }
}

@media screen and (min-width: 800px) {
  .cart {
    &__grid {
      grid-template-columns: 1fr 1fr;
      align-items: flex-start;
      gap: 4rem;
    }
  }
}

@media screen and (min-width: 1000px) {
  .cart {
    padding: 2.5rem 4rem 4rem;
    &__grid {
      gap: 5rem;
      &__items {
        gap: 3.5rem;
        &__empty {
          &-title {
            font-size: 2.4rem;
            margin-top: 5rem;
          }
          &-img {
            max-width: 30rem;
          }
          &-link {
            font-size: 1.8rem;
          }
        }
      }
      &__info {
        padding: 3rem;
        &__title {
          font-weight: 800;
          font-size: 2.5rem;
          padding: 1.5rem 0;
        }
        &__container {
          gap: 1.5rem;
          margin: 2.5rem 0;
          &__div {
            &-name {
              font-size: 1.8rem;
            }
            &-price {
              font-size: 1.8rem;
            }
          }
        }
        &__div {
          padding: 2.5rem 0 3.5rem;
          &-name {
            font-size: 1.8rem;
          }
          &-price {
            font-size: 1.8rem;
          }
        }
        &__button {
          padding: 1.3rem;
          font-size: 2rem;
          text-align: center;
        }
      }
    }
  }
}


@keyframes emptyCart {
  from {
    transform: scale(.95);
  }
  to {
    transform: scale(1.02);
  }
}