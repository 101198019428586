.logo {
  font-size: 2rem;
  width: 8rem;
  text-align: center;
  color: $Black;
  line-height: 1;
  font-weight: 600;
  text-transform: uppercase;
  position: relative;
  z-index: 5;
  span {
    color: $White;
  }
}

.cart-price {
  position: absolute;
  top: 20px;
  font-size: 1.2rem;
  color: black;
}

@media screen and (min-width: 1000px) {
  .logo {
    font-size: 2.5rem;
    width: 10rem;
  }
}