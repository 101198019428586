/* Reseteamos los margin y paddings de todas las etiquetas */
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
  vertical-align: baseline;
}

*::before,
*::after {
  display: block;
}

/* Evitamos problemas con las imagenes */
img,
picture,
video,
iframe,
figure {
  max-width: 100%;
  width: 100%;
  display: block;
  /* (Opcional) */
  object-fit: cover;
  /* (Opcional) */
  object-position: center center;
}

/* Reseteamos los enlaces para funcionar como cajas... */
a {
  display: block;
  text-decoration: none;
  color: inherit;
  font-size: inherit;
}

/* ... excepto los que se encuentran en párrafos */
p a {
  display: inline;
}

/* Quitamos los puntos de los <li> */
li {
  list-style-type: none;
}

/* (Opcional) Configuramos anclas suaves */
html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}

/* Evitamos problemas con los pseudoelementos de quotes */
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

/* Nivelamos problemas de tipografías y colocación de formularios */
form,
input,
textarea,
select,
button,
label {
  font-family: inherit;
  font-size: inherit;
  hyphens: auto;
  background-color: transparent;
  color: inherit;
  display: block;
  /* (Opcional) */
  appearance: none;
  outline: none;
}

/* Reseteamos las tablas */
table,
tr,
td {
  border-collapse: collapse;
  border-spacing: 0;
}

/* Evitamos problemas con los SVG */
svg {
  width: 100%;
  display: block;
  fill: currentColor;
}

/* Configuramos la tipografía para toda la web */
body {
  min-height: 100vh;
  font-size: 100%;
  font-family: $Poppins;
  color: #393939;
  /* (Opcional) */
  hyphens: auto;
  /* (Opcional) */
  font-smooth: always;
  /* (Opcional) */
  -webkit-font-smoothing: antialiased;
  /* (Opcional) */
  -moz-osx-font-smoothing: grayscale;
  background-color: $Gray-light;
}
#root {
  max-width: 1400px;
  margin: 0 auto;
}