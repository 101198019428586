.menu {
  padding: 8rem 2rem;
  &__types {
    padding: 2rem 1rem;
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    gap: 2rem;
    overflow-x: scroll;
    &::-webkit-scrollbar {
      background-color: $Gray-light;
      height: .3rem;
      border-radius: .5rem;
    }
    &::-webkit-scrollbar-thumb{
      border-radius: .5rem;
      background-color: $Orange;
    }
  }
  &__container {
    margin-top: 6rem;
    display: grid;
    grid-auto-flow: row;
    div {
      max-width: 34rem;
    }
  }
}

@media screen and (min-width: 700px) {
  .menu {
    &__types {
      justify-content: center;
      overflow-x: scroll;
      &::-webkit-scrollbar {
        background-color: $Gray-light;
        height: .3rem;
        border-radius: .5rem;
      }
      &::-webkit-scrollbar-thumb{
        border-radius: .5rem;
        background-color: $Orange;
      }
    }
  }
}

@media screen and (min-width: 750px) {
  .menu {
    &__container {
      margin-top: 5rem;
      grid-template-columns: 1fr 1fr;
      gap: 2rem;
      div {
        margin-bottom: 2rem;
      }
    }
  }
}

@media screen and (min-width: 1100px) {
  .menu {
    padding: 6rem 4rem;
    &__types {
      gap: 4rem;
    }
    &__container {
      grid-template-columns: 1fr 1fr 1fr;
      gap: 4rem;
      div {
        max-width: none;
      }
    }
  }
}