.card {
  width: 100%;
  max-width: 40rem;
  margin: 0 auto 5rem;
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.40);
  box-shadow: 5px 5px 10px 0px rgba(23, 23, 23, 0.10);
  padding: 2rem;
  &__img {
    width: 100%;
    height: 200px;
    object-fit: contain;
  }
  &__name {
    font-size: 2.5rem;
    font-weight: 800;
    color: $Black;
    margin-top: 1rem;
  }
  &__info {
    font-size: 1.6rem;
    color: $Gray;
    margin-bottom: 2rem;
  }
  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &__price {
      color: $Black;
      font-size: 2.5rem;
      font-weight: 600;
    }
    &__button {
      display: grid;
      place-content: center;
      width: 4rem;
      height: 4rem;
      background-color: $Orange;
      font-size: 2.2rem;
      border-radius: 100%;
      color: $White;
      transition: transform .3s;
      cursor: pointer;
      &:active {
        transform: translateY(0.4rem);
      }
    }
  }
}

@media screen and (min-width: 1275px) {
  .card {
    padding: 2.5rem;
    &__img {
      height: 220px;
    }
    &__name {
      font-size: 2.6rem;
      margin-top: 1.3rem;
    }
    &__info {
      font-size: 1.8rem;
      margin-bottom: 2.2rem;
    }
    &__container {
      &__price {
        font-size: 3rem;
      }
      &__button {
        width: 4.5rem;
        height: 4.5rem;
        font-size: 2.5rem;
      }
    }
  }
}