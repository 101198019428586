.item {
  background-color: $White;
  border-radius: 1.5rem;
  box-shadow: 5px 5px 10px 0px rgba($Black, 0.15);
  padding: 1.5rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 1rem;
  position: relative;
  width: 100%;
  max-width: 40rem;
  margin: 0 auto;
  font-weight: 700;
  font-size: 1.2rem;
  &__trash {
    position: absolute;
    top: 1rem;
    right: 1rem;
    border-radius: 100%;
    background-color: $Orange;
    font-size: 2rem;
    color: $White;
    height: 3rem;
    width: 3rem;
    border: .1rem solid $Orange;
    cursor: pointer;
    transition-property: transform background-color color;
    transition-duration: .3s;
    &:active {
      transform: translateY(.3rem);
    }
    &:hover {
      background-color: $White;
      color: $Orange;
    }
  }
  .item__img {
    width: 100%;
    height: 100%;
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    object-fit: contain;
    margin: auto;
  }
  &__data {
    grid-column: 2 / 4;
    grid-row: 1 / 2;
    &__name {
      color: $Black;
      font-weight: 600;
      font-size: 2rem;
    }
    &__description {
      font-size: 1.6rem;
      color: $Gray;
    }
    &__grid {
      display: grid;
      grid-auto-flow: column;
      justify-content: space-between;
      align-items: center;
      margin-top: 2rem;
      &__quantity {
        display: grid;
        grid-auto-flow: column;
        gap: 1.2rem;
        span {
          font-size: 1.6rem;
          color: $Black;
          font-weight: 600;
        }
        button {
          img {
            width: 2rem;
          }
          cursor: pointer;
          transition-property: transform ;
          transition-duration: .3s;
          &:active {
            transform: translateY(.3rem);
          }
        }
      }
      &__price {
        font-size: 2rem;
        font-weight: 600;
        color: $Black;
      }
    }
  }
}

@media screen and (min-width: 1000px) {
  .item {
    padding: 2rem;
    gap: 1.5rem;
    max-width: 50rem;
    &__trash {
      font-size: 2.2rem;
      height: 3.5rem;
      width: 3.5rem;
    }
    &__data {
      &__name {
        font-size: 2.5rem;
      }
      &__description {
        font-size: 1.8rem;
      }
      &__grid {
        margin-top: 3rem;
        &__quantity {
          gap: 1.5rem;
          span {
            font-size: 1.8rem;
          }
          button {
            img {
              width: 2.2rem;
            }
          }
        }
        &__price {
          font-size: 2.2rem;
        }
      }
    }
  }
}