
html,
label {
  font: 400 16px/24px Roboto, Noto Sans, Noto Sans JP, Noto Sans KR,
    Noto Naskh Arabic, Noto Sans Thai, Noto Sans Hebrew, Noto Sans Bengali,
    sans-serif;
}

form {
  display: inline-block;
}

form.top-bottom {
  margin: -4px -5px;
}

form.top-bottom label {
  margin-top: 10px;
}

form label {
  display: block;
}

form label.control {
  display: inline-block;
  width: 200px;
  margin: 4px 5px;
}

form label > span {
  display: inline-block;
  margin-right: 5px;
}

form label.control > span {
  display: block;
}

form label .value {
  font-size: 80%;
  color: #666;
}

form select {
  -moz-appearance: none;
  -webkit-appearance: none;
  border: 1px solid #dadce0;
  border-radius: 4px;
  box-shadow: none;
  color: #202124;
  cursor: pointer;
  display: inline-block;
  font: 500 14px/36px Google Sans, Noto Sans, Noto Sans JP, Noto Sans KR,
    Noto Naskh Arabic, Noto Sans Thai, Noto Sans Hebrew, Noto Sans Bengali,
    sans-serif;
  height: 36px;
  line-height: 34px;
  outline: 0;
  padding: 0 27px 0 15px;
  text-align: left;
  text-indent: 0.01px;
  text-overflow: ellipsis;
  transition: background-color 0.2s;
  vertical-align: middle;
  white-space: nowrap;
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="4" viewBox="0 0 20 4"><path d="M0,0l4,4l4-4H0z" fill="%23212121"/></svg>')
    #fff no-repeat 100%;
}

form.top-bottom select {
  width: 100%;
}

form option {
  font-weight: normal;
  display: block;
  white-space: pre;
  min-height: 1.2em;
  padding: 0 2px 1px;
}

form input[type="range"] {
  width: 100%;
}

.demo {
  margin-top: 0px;
  margin-bottom: 0px;
  min-height: 200px;
}

.note {
  font: 400 16px/24px Roboto, Noto Sans, Noto Sans JP, Noto Sans KR,
    Noto Naskh Arabic, Noto Sans Thai, Noto Sans Hebrew, Noto Sans Bengali,
    sans-serif;
}

.mms {
  font-family: "Roboto", sans-serif;
  padding: 0;
  margin: 0;
  background-color: #f5f5f5;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.cookiesContent {
  width: 320px;
  background-color: pink;
  margin: auto;
  gap: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  color: #000;
  text-align: center;
  border-radius: 20px;
  padding: 25px 50px 25px;
  transform: translate(0, 100px);
  border-color: green;
  button.close {
    width: 30px;
    font-size: 20px;
    color: #c0c5cb;
    align-self: flex-end;
    background-color: transparent;
    border: none;
    margin-bottom: 10px;
  }
  img {
    width: 70%;
    height: auto;

  }
  .purchaseTopic {
    margin-bottom: 40px;
    font-size: 18px;
    color: red;
    
  }
  button.accept {
    background-color: #ed6755;
    border: none;
    border-radius: 5px;
    width: 200px;
    padding: 14px;
    font-size: 16px;
    color: white;
    box-shadow: 0px 6px 18px -5px rgba(237, 103, 85, 1);
  }

 

  
}
